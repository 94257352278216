import React from "react"
import styled from "styled-components";
import Layout from "../templates/layout"
import Topography from '../components/topography-section'
import Wrapper from '../components/container'
import Hero from '../components/hero'
import Img from "gatsby-image/withIEPolyfill"
import H1 from '../components/typography/h1'
import H3 from '../components/typography/h3'
import H2 from '../components/typography/h2'
import P from '../components/typography/p'

import { graphql } from 'gatsby'


const IndexPage = ({ data }) => (
  <Layout
    title="About Us | Loveless Outdoor Adventures"
    description="Learn more about Damian Loveless and how he became the best bowfishing, catfishing, and duck hunting guide on Kentucky Lake"
  >
    <Hero title="Meet your guide" tagline="who will be your fearless leader?" />
    <Topography>
    <Wrapper>
            <Flexbox>
                <Box>
                    <Img
                        fluid={data.about.childImageSharp.fluid}
                        objectFit='cover'
                        objectPosition='50% 50%'
                        alt='Damian Loveless'
                        style={{ height: "100%" }}
                    />
                </Box>
                <Box>
                    <H3>What makes us the best guide on Kentucky Lake?</H3>
                    <H1>About Loveless Outdoor Adventures</H1>

                        <P>Damian Loveless was born in Western KY, where from a young age he enjoyed the outdoors. The waterways and woodlands of Kentucky offered an endless opportunity to be a sportsman, with the day’s adventures constantly changing with the seasons. In his youth, his love for the outdoors steered him to pursue both fish and game in virtually every minute of his free time.</P>
                </Box>
            </Flexbox>

            <P>During his teenage years Damian began commercial fishing the lakes of Western Kentucky. Together, he and his older brother used trotlines to harvest catfish. For several years they fished together, where he learned the commercial fishing trade and later was able to take over the business when his big brother moved on to pursue other professional opportunities. He continued to fish commercially until age 23 and learned a lot about the behavior of catfish on his home waters. Damian recalls some of the best memories he has are the days spent with his brother pulling fish over the side of an old beat-up StarCraft boat.</P>

            <P>After high school, Damian also furthered his education at Murray State University, earning a Bachelor’s of Biology with a minor in chemistry.  He later became a biology teacher at a local high school. A Master’s degree in school administration soon followed. Now in his 22nd year at that same high school, Damian is still teaching kids about the subjects that parallel his hobbies and life.</P>

            <P>During the time he has been a teacher, Damian has continued to pursue his early interests, even adding some new ones. He began bowfishing in his early teens and has since enjoyed countless nights and days on the water with his friends. His tournament bowfishing habit started in the early 2000’s. Damian’s team, led by mentor Dennis Redden, has competed in many tournaments, spending countless hours prowling the waters. It was during this time that Damian honed his skills at locating and killing rough fish with archery gear. The team earned a sponsorship with AMS Bowfishing, who began supporting them with bows, arrows, and other essential gear. This relationship has been a good one and continues even now.</P>

            <P>Damian spends many days per year on the water enjoying the hobbies he pursues. He loves the rush of hand fishing for big flathead catfish and using a rod and reel. It doesn’t matter if it’s bitter cold, deep-water blue cats and crappie, springtime bluegill and channel cats, or mid-summer pursuits, you can bet he will be wetting a line somewhere. He also loves to gun the open water for diver ducks in late winter from layout boats. There are many hunting and angling opportunities in KY, and Damian doesn’t like to miss them.</P>

            <P>Being a teacher allows ample time to pursue other passions in life. After much consideration and preparation, Damian decided to start “Loveless Outdoor Adventures” where he could introduce people to the same waters he explored in his youth and show them why Kentucky was a great place to grow up. Damian has a passion for the outdoors, and he will do his best to make sure you have success when you are in his boat. Western KY is a sportsman’s paradise so get out on the water and explore what we have to offer.</P>

            <H2 textAlign="center">The Boats</H2>
            <Flexbox>
              <Box>
              <Img fluid={data.boat.childImageSharp.fluid} style={{ height: "100%" }}/>
              </Box>
              <Box>
              <P>Boat:1 24 foot Sea Ark. The platform is removed, when necessary, during warm weather for rod and reel charters and during the winter months. The 115 outboard provides plenty of safe power.</P>
              <P>Boat:2 We've also added a 2nd boatto the fleet. 24ft SeaArk Big Easy with 225HP Suzuki. Plenty of power for pleanty of people</P>
              </Box>
            </Flexbox>
        </Wrapper>
    </Topography>
  </Layout>

)


const Flexbox = styled.div`
    @media(min-width: 1000px) {
      display: flex;
    }
`
const Box = styled.div`
    @media(min-width: 1000px) {
        width: calc(100% / 2 - 50px);
        :nth-child(1) {
            margin-right: 50px;
        }
    }
`

export const query = graphql`
query {
  about: file(relativePath: { eq: "about.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  boat: file(relativePath: { eq: "boat.jpg" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

export default IndexPage
