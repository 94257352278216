import React from 'react'
import styled from "styled-components";
import * as PropTypes from "prop-types";

const h2 = ({ children, textAlign }) => {
    return (
        <H1 textAlign={textAlign}>{children}<hr /></H1>
    );
};

h2.propTypes = {
    children: PropTypes.node.isRequired,
    textAlign: PropTypes.oneOf(["left", "center"]),
};

h2.defaultProps = {
    textAlign: "left",
};

const H1 = styled.h2`
    text-transform: uppercase;
    font-display: auto;
    font-family: 'Teko';
    color: #21201E;
    font-weight: 500;
    font-size: 2rem;
    text-align: ${props => props.textAlign};
    padding-bottom: .5em;
    @media (min-width: 800px) {
        font-size: 2rem;
        padding-bottom: 0em;
    }
    hr {
        background-color: #755A4D;
        border: none;
        color: #755A4D;
        height: 4px;
        margin-top: 2px;
        position: ${props => (props.textAlign === "center" ? "relative" : "absolute")};
        width: 75px;
    }
    }
`



export default h2;